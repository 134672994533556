@import "variables.scss";
@import "animation.scss";
@import "page.scss";

* {
    box-sizing: border-box;
}

body, html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

body {
    background-color :$page-background;
    color: $primary;  

    font-size: 16px;
    font-family: 'VT323', monospace;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;    
}
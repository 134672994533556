@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }  
    @-ms-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
      @content;
    }  
    @keyframes #{$animation-name} {
      @content;
    }
  }

  @mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
  }
  